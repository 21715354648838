import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  ASSET_NAME,
  getRecommendedListingParams,
  getWhatsSellingListingsParams,
  whatsSellingSectionId,
  recommendedSectionId,
  fetchWhatsSellingListings,
  fetchRecommendedListings,
} from './LandingPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import {
  SectionAutoCarousel,
  SectionGrid,
  SectionRecommendedListings,
  SectionWhatSellingListings,
  SectionFeaturedCategories,
  SectionFAQ,
} from '../PageBuilder/SectionBuilder';
import {
  BlockCategories,
  BlockDiscoverFavouriteBrands,
  BlockFAQ,
  BlockGridCard,
} from '../PageBuilder/BlockBuilder';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const HeroSectionType = 'HeroSection';
const heroSectionId = 'hero-section';

const DiscoverFavouriteBrandsType = 'DiscoverFavouriteBrands';
const discoverFavouriteBrandsId = 'discover-brands';

const GridBlockCardType = 'GridBlockCard';

const DiscoverFavouriteBrandsBlockType = 'DiscoverFavouriteBrandsBlock';
const FeaturedCategoriesBlockType = 'FeaturedCategoriesBlock';
const FAQBlockType = 'FAQBlock';

const recommendedSectionType = 'recommended';
const whatsSellingSectionType = 'whats-selling';

const SectionFeaturedCategoriesType = 'section-featured-categories';
const SectionFeaturedCategoriesId = 'featured-categories';

const SectionFAQType = 'section-faq';
const SectionFAQId = 'faq-section';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    onFetchRecommendedListings,
    onFetchWhatsSellingListings,
    whatsSellingListings,
    recommendedListings,
    whatsSellingLoading,
    recommendedLoading,
    currentUser,
    onUpdateWishlist,
  } = props;

  const config = useConfiguration();
  useEffect(() => {
    const fetchWhatsSellingListings = () => {
      const whatsSellingParams = getWhatsSellingListingsParams(config);
      onFetchWhatsSellingListings(whatsSellingParams);
    };
    const fetchRecommendedListings = () => {
      const recommendedParams = getRecommendedListingParams(config);
      onFetchRecommendedListings(recommendedParams);
    };

    // Fetch both sets of listings independently
    fetchWhatsSellingListings();
    fetchRecommendedListings();
  }, [config, onFetchWhatsSellingListings, onFetchRecommendedListings]);

  // Construct custom page data
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  // Find all the custom blocks based on block IDs
  const gridBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block =>
      block?.blockId?.startsWith('grid-block-')
    );
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // Find all the discoverFavouriteBlocks based on block IDs
  const discoverFavouriteBrandsBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block =>
      block?.blockId?.startsWith('discover-favourite-brands-block-')
    );
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // find all the faq blocks based on block IDs
  const faqBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block => block?.blockId?.startsWith('faq-card-'));
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // find all custom blocks based on block IDs
  const featuredCategoriesBlocks = pageData?.sections.reduce((blocks, section) => {
    const matchingBlocks = section.blocks?.filter(block =>
      block?.blockId?.startsWith('featured-categories-block-')
    );
    return matchingBlocks ? [...blocks, ...matchingBlocks] : blocks;
  }, []);

  // Find the correct custom section based on section id
  const heroSectionIdx = pageData?.sections.findIndex(s => s.sectionId === heroSectionId);
  const heroSection = pageData?.sections[heroSectionIdx];

  // faq section
  const faqSectionIdx = pageData?.sections.findIndex(s => s.sectionId === SectionFAQId);
  const faqSection = pageData?.sections[faqSectionIdx];

  // recommended section
  const recommendedSectionIdx = pageData?.sections.findIndex(
    s => s.sectionId === recommendedSectionId
  );
  const recommendedSection = pageData?.sections[recommendedSectionIdx];

  // whats selling section
  const whatsSellingSectionIdx = pageData?.sections.findIndex(
    s => s.sectionId === whatsSellingSectionId
  );
  const whatsSellingSection = pageData?.sections[whatsSellingSectionIdx];

  // discover favourite brands section
  const discoverFavouriteBrandsIdx = pageData?.sections.findIndex(
    s => s.sectionId === discoverFavouriteBrandsId
  );
  const discoverFavouriteBrandsSection = pageData?.sections[discoverFavouriteBrandsIdx];

  // featured categories section
  const featuredCategoriesIdx = pageData?.sections.findIndex(
    s => s.sectionId === SectionFeaturedCategoriesId
  );
  const featuredCategoriesSection = pageData?.sections[featuredCategoriesIdx];

  //define the necessary props for the featured categories section
  const customFeaturedCategoriesSection = {
    ...featuredCategoriesSection,
    sectionId: SectionFeaturedCategoriesId,
    sectionType: SectionFeaturedCategoriesType,
  };

  // Define the necessary props for the discover favourite brands section
  const customDiscoverFavouriteBrandsSection = {
    ...discoverFavouriteBrandsSection,
    sectionId: discoverFavouriteBrandsId,
    sectionType: DiscoverFavouriteBrandsType,
  };

  // Define the necessary props for the hero section
  const customHeroSection = {
    ...heroSection,
    sectionId: heroSectionId,
    sectionType: HeroSectionType,
  };

  // Define the necessary props for the faq section
  const customFAQSection = {
    ...faqSection,
    sectionId: SectionFAQId,
    sectionType: SectionFAQType,
  };

  // Define the necessary props for the recommended section
  const customRecommendedSection = {
    ...recommendedSection,
    sectionId: recommendedSectionId,
    sectionType: recommendedSectionType,
    listings: recommendedListings,
    isLoading: recommendedLoading,
    currentUser,
    onUpdateWishlist,
  };

  // Define the necessary props for the whats selling section
  const customWhatsSellingSection = {
    ...whatsSellingSection,
    sectionId: whatsSellingSectionId,
    sectionType: whatsSellingSectionType,
    listings: whatsSellingListings,
    isLoading: whatsSellingLoading,
    currentUser,
    onUpdateWishlist,
  };

  // add the custom block to the custom section
  customHeroSection.blocks = gridBlocks || [];
  customDiscoverFavouriteBrandsSection.blocks = discoverFavouriteBrandsBlocks || [];
  customFAQSection.blocks = faqBlocks || [];
  customFeaturedCategoriesSection.blocks = featuredCategoriesBlocks || [];

  // add block type to the custom block
  customHeroSection.blocks.forEach(block => {
    block.blockType = GridBlockCardType;
  });

  customDiscoverFavouriteBrandsSection.blocks.forEach(block => {
    block.blockType = DiscoverFavouriteBrandsBlockType;
  });

  customFAQSection.blocks.forEach(block => {
    block.blockType = FAQBlockType;
  });

  customFeaturedCategoriesSection.blocks.forEach(block => {
    block.blockType = FeaturedCategoriesBlockType;
  });

  const customSections = pageData
    ? [
        // add more sections here if needed
        ...pageData?.sections?.map((s, idx) => {
          if (idx === heroSectionIdx) {
            return customHeroSection;
          } else if (idx === discoverFavouriteBrandsIdx) {
            return customDiscoverFavouriteBrandsSection;
          } else if (idx === recommendedSectionIdx) {
            return customRecommendedSection;
          } else if (idx === whatsSellingSectionIdx) {
            return customWhatsSellingSection;
          } else if (idx === featuredCategoriesIdx) {
            return customFeaturedCategoriesSection;
          } else if (idx === faqSectionIdx) {
            return customFAQSection;
          } else {
            return s;
          }
        }),
      ]
    : pageData;

  const customPageData = pageData
    ? {
        ...pageData,
        sections: customSections,
      }
    : pageData;

  return (
    <>
      <PageBuilder
        pageAssetsData={customPageData}
        inProgress={inProgress}
        currentPage={'landingPage'}
        options={{
          sectionComponents: {
            [HeroSectionType]: { component: SectionGrid },
            [whatsSellingSectionType]: { component: SectionWhatSellingListings },
            [DiscoverFavouriteBrandsType]: { component: SectionAutoCarousel },
            [recommendedSectionType]: { component: SectionRecommendedListings },
            [SectionFeaturedCategoriesType]: { component: SectionFeaturedCategories },
            [SectionFAQType]: { component: SectionFAQ },
          },
          blockComponents: {
            [GridBlockCardType]: { component: BlockGridCard },
            [DiscoverFavouriteBrandsBlockType]: { component: BlockDiscoverFavouriteBrands },
            [FAQBlockType]: { component: BlockFAQ },
            [FeaturedCategoriesBlockType]: { component: BlockCategories },
          },
        }}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  // These are the ids for the listings returned from the API –
  // they may be different than the Console ones: if for example
  // one of the listings was closed, it does not get returned from the API

  const whatsSellingListings = state.LandingPage.whatsSellingListings;
  const recommendedListings = state.LandingPage.recommendedListings;
  const whatsSellingLoading = state.LandingPage.whatsSellingLoading;
  const recommendedLoading = state.LandingPage.recommendedLoading;
  return {
    pageAssetsData,
    currentUser: state.user.currentUser,
    whatsSellingListings,
    recommendedListings,
    whatsSellingLoading,
    recommendedLoading,
    inProgress,
    error,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchRecommendedListings: params => dispatch(fetchRecommendedListings(params)),
  onFetchWhatsSellingListings: params => dispatch(fetchWhatsSellingListings(params)),
  onUpdateWishlist: payload => dispatch(updateProfile(payload)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);

export default LandingPage;
